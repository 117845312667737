<template>
  <div class="unit-type-card p-1">
    <div
      class="flat-card p-2 cursor-pointer"
      @click="toDetailPage(unitType.id)"
    >
      <div class="row align-content-between h-100">
        <div class="col-12">
          <p class="unit-type-name">
            {{ unitType.name }}
          </p>
          <p>{{ unitType.propertyType.name }}</p>
          <p v-if="unitType.completionDate" class="completion-date mt-1">
            <i class="fas fa-calendar mr-1"></i>{{ unitType.completionDate }}
          </p>
        </div>
        <div class="col-12 mt-2">
          <span
            v-for="phase in unitType.projectPhases"
            :key="phase.id"
            class="available-phases"
          >
            {{ phase.name }}
          </span>
        </div>
        <div class="col-12 text-right">
          <!-- Edit unit type -->
          <button
            v-if="canEdit"
            class="btn flat mx-1"
            @click.stop="editUnitType(unitType.id)"
          >
            <i class="fas fa-pen"></i>
          </button>
          <!-- Delete unit type -->
          <button
            v-if="canDelete"
            class="btn flat mx-1"
            @click.stop="deleteUnitType(unitType.id, unitType.name)"
          >
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    unitType: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    toDetailPage(id) {
      this.$emit("toDetail", id);
    },
    editUnitType(id) {
      this.$emit("edit", id);
    },
    async deleteUnitType(id, name) {
      let confirmDelete = await this.$confirm({
        type: "alert",
        message: `Are you sure you want to delete unit type "${name}"? This action cannot be undone.`
      });

      if (confirmDelete) {
        this.$emit("delete", id);
      }
    }
  }
};
</script>

<style lang="scss">
.unit-type-card {
  height: auto;
  .unit-type-name {
    font-size: 18px;
    font-weight: bold;
  }

  .flat-card {
    border: solid 1px #cccccc;
    border-radius: 5px;
    background: white;
    height: 100%;

    &:hover {
      transition: 0.3s;
      box-shadow: 0px 5px 10px #00000025;
    }
  }

  .completion-date {
    width: fit-content;
    padding: 4px 12px;
    border-radius: 4px;
    border: solid 1px $color-1;
  }

  .available-phases {
    background: lighten($color-secondary, 45);
    color: $color-secondary;
    padding: 4px 12px;
    border-radius: 50em;
    font-size: 12px;
    margin: 0 4px;
  }
}
</style>
